import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { SeoCMS } from 'utils/sharedStrapiQueries/sharedTypes';

export interface SEOProps {
  seo: SeoCMS;
}

const SEO: React.FunctionComponent<SEOProps> = ({ seo }) => {
  const [isMounted, setMounted] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    setMounted(true);
  }, []);

  const origin = useMemo(
    () =>
      (isMounted &&
        (window.location.href.endsWith('/')
          ? window.location.href.slice(0, window.location.href.length - 1)
          : window.location.href)) ||
      '', // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router?.asPath]
  );

  const fullPath = useMemo(
    () => (isMounted && window.location.href) || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router?.asPath]
  );

  const domain = useMemo(
    () => (isMounted && window.location.host) || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, router?.asPath]
  );

  return (
    <>
      {seo && (
        <Head>
          {seo?.title && <title>{seo.title}</title>}
          {seo?.description && (
            <meta name="description" content={seo.description} />
          )}
          {seo?.title && <meta property="og:type" content={seo.ogType} />}
          {seo?.ogTitle && <meta property="og:title" content={seo.ogTitle} />}
          {seo?.ogDescription && (
            <meta property="og:description" content={seo.ogDescription} />
          )}
          {seo?.ogImage && (
            <meta property="og:image" content={seo.ogImage.url} />
          )}
          <meta property="og:url" content={fullPath} />
          <link rel="canonical" href={origin} />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={domain} />
          <meta property="twitter:url" content={fullPath} />
          {seo?.ogTitle && <meta name="twitter:title" content={seo.ogTitle} />}
          {seo?.ogDescription && (
            <meta name="twitter:description" content={seo.ogDescription} />
          )}
          {seo?.ogImage && (
            <meta name="twitter:image" content={seo.ogImage.url} />
          )}
        </Head>
      )}
    </>
  );
};
export default SEO;
